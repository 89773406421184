<template>
	<main>
		<section class="about-area pb-60 pt-100">
			<div class="container">
				<div class="row">
					<div class="col-lg-6 col-xs-12">
						<div class="section-title pb-20">
							<h4>What is Domain Registration ?</h4>
						</div>

						<p>
							In order for your business, organization or portal to go online,
							It requires a webiste. Your website requires a name to be
							identified over internet; i.e. Your website visitors will require
							that name to visit your website. This name is required to be
							registered before it can be used with your website. The process of
							registring your name is known as domain registration.
						</p>
						<p>
							All the website that you visit over internet, you remember them by
							a specific name and that name is called their domain name. All
							those website owners have registered their respective domain name
							before publically launching their website.
						</p>
					</div>

					<div class="col-lg-6 col-xs-12">
						<img src="assets/img/service/domain.jpg" class="img-fluid" />
					</div>
				</div>
			</div>
		</section>

		<section class="about-area grey-bg pb-60 pt-100">
			<div class="container">
				<div class="row">
					<div class="col-lg-7 col-xs-12">
						<div class="section-title pb-10">
							<h4>
								Do I need to have a website before registering a domain name ?
							</h4>
						</div>
						<p>
							No, you can register domain name, even if you don’t have any
							website. In fact, there are so many domains being registered
							without any website. You can use your domain name in order to
							create email addresses that refer to your company name, such as,
							you@yourcompanyname.com
						</p>

						<div class="section-title pb-10 pt-50">
							<h4>What should I choose as a domain name for my website ?</h4>
						</div>
						<p>
							A domain should usually refer to the name of your business,
							organization, product or nature of business you want to
							publishise. However you need to make sure that it is easy to
							remember and short enough to be spelled correctly. A domain name
							can contain letters (a-z), numbers (0-9) and hyphens (-) and must
							not have any spaces in between.
						</p>
					</div>
					<div class="col-lg-5 col-xs-12">
						<div
							class="domain_wrap"
							style="background-image:url(assets/img/bg/domain.jpg);"
						>
							<div class="domain_wrap_title text-center pt-100">
								<h4>Do You Want To Register Domain ?</h4>
							</div>
							<div class="domain_text">
								<p>
									Once somebody registers a domain name, that domain name won’t
									be available to anyone else.
								</p>
								<p>
									So, If you want to register your domain name, you need to
									first check whether it is available or not.
								</p>
							</div>
							<div class="domain_btn text-center">
								<a href="javascript:void(0)" @click="$router.push('/contact')" class="domain-btn">Check Availability</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="about-area pb-60 pt-40">
			<div class="container">
				<div class="row">
					<div class="col-md-6 col-xs-12">
						<div class="section-title pb-10 pt-50">
							<h4>
								What if domain name that I want to register has already been
								registered ?
							</h4>
						</div>
						<p>
							You can either look up for its owner, contact him and ask him to
							give it to you or wait to be get dropped or hire domain catchers
							who assist you in obtaining that domain name for you or you can
							choose other TLD (Top Level Domain) while retaining the name you
							want to register; i.e. If yourcompany.com is already registered,
							If available, You can register yourcompany.co or yourcompany.co.in
							or yourcompany.in.
						</p>

						<p>
							If none of the above is possible, you need to make a change in
							your domain name.
						</p>
					</div>
					<div class="col-md-6 col-xs-12">
						<div class="section-title pb-10 pt-50">
							<h4>
								What if I already have registered a domain and want to transfer
								it to you ?
							</h4>
						</div>
						<p>
							If you have access to the control panel of your domain, You need
							to generate an authcode and share it with us. We will take
							responsibility of the rest of the process. During the domain
							transfer process, you might be contacted by your current registrar
							asking for reason of transfering the domain. You may also receive
							an email during the domain transfer process to verify whether it
							was you who initited the process.
						</p>
					</div>
				</div>
			</div>
		</section>

		<section class="about-area domain-footer pb-60 pt-40">
			<div class="container">
				<div class="row pt-30">
					<div class="col-md-10 col-xs-12">
						<div class="domain_wrap_title">
							<h4>
								That’s enough of an information, Let me register my domain…
							</h4>
						</div>
					</div>
					<div class="col-md-2 col-xs-12">
						<div class="domain_btn text-center">
							<a href="javascript:void(0)" @click="$router.push('/contact')" class="domain-btn">Register Now</a>
						</div>
					</div>
				</div>
			</div>
		</section>
	</main>
</template>

<script>
export default {
	name: "domainregistration",
};
</script>

<style></style>
